<div class="footer dont-print" [class.no-pointer-events]="isUsingSebSoftware()">
  <div *wlCtx="'POST_INFO_RESTRICT'">
    <div *ngIf="hasLinks && getFooter()" class="footer-links">
      <div class="footer-column" *ngFor="let footerColumn of getFooter()">
        <div
          *ngFor="let footer of footerColumn"
          [ngSwitch]="isFooterInternal(footer)"
        >
          <a *ngSwitchCase="true" routerLink="/{{clang()}}{{footer.route}}"><tra [slug]="footer.caption"></tra></a>
          <a *ngSwitchCase="false" target="_blank" href="{{lang.tra(footer.link)}}"><tra [slug]="footer.caption"></tra></a>
        </div>
      </div>
  </div>
  </div>
  <div class="footer-lower">
      <div class="copyright">
        <tra [slug]="getCopyright()"></tra>
      </div>
      <div *wlCtx="'POST_INFO_RESTRICT'">
        <button *ngIf="isAppealShown && allowAppeals.sysAllow" [routerLink]="renderAppealLink()" class="footer-comment-link">
          <tra slug="btn_file_an_appeal"></tra>
        </button>
        <span *ngIf="isAppealShown && allowAppeals.sysAllow" style="color: #999;margin:0em 0.5em;">•</span>
        <button *ngIf="hasComments" (click)="supportReqActivate()" class="footer-comment-link technical-support">
          <tra slug="title_feedback"></tra>
        </button>
      </div>
  </div>
</div>