<div style="display:flex;justify-content: space-between; padding:5px;">
    <div>         
      <tra slug="lbl_instit"></tra>
      <select [formControl]="currentSelectedInstitution" (change)="filterByInstitution()">
        <option [ngValue]="'NO_INSTIT'"><tra slug="lbl_select_institution"></tra></option>
        <option *ngFor="let instit of institutionResults" [ngValue]="instit">{{instit.name}}</option>
      </select>
    </div>
    <div *ngIf="!isFailed"></div>
    <div *ngIf="isFailed" style="display:flex;align-items:center;"><tra slug="msg_error"></tra> {{this.failReason}}</div>
    <div style="display:flex;align-itemcenters:;">
      <button (click)="pageShift(-1)" [disabled]="isMin()" class="button is-link"> < </button>
      <tra-md [isCondensed]="true" slug="lbl_showing_page" [props]="{
        data_count: Math.min(this.totalDataCount, (this.currentPage+1)*10),
        total_data_count: this.totalDataCount
      }"></tra-md> &nbsp;
      <button (click)="pageShift(1)" [disabled]="isMax()" class="button is-link"> > </button>
    </div>
  </div>
  <div class="table-holder-container" style="overflow-x: auto;">
    <!-- <table class="table is-bordered"> -->
    <table class="table is-bordered is-hoverable is-number-table" style="min-width: 34em; table-layout: fixed; width: 100%;">  
      <tr>
        <th><tra slug="lbl_instit_group_id"></tra></th>
        <th><tra slug="lbl_instit_id"></tra></th>
        <th><tra slug="lbl_test_session_id"></tra></th> 
        <th><tra slug="lbl_form_uploaded_time"></tra></th>
        <th><tra slug="lbl_download_form"></tra></th>         
        <th><tra slug="lbl_verified"></tra></th>
        <th><tra slug="lbl_verified_on"></tra></th>
        <th><tra slug="lbl_verified_by_UID"></tra></th>
        <th><tra slug="lbl_mark_verify"></tra></th>
        <th><tra slug="lbl_mark_unverify"></tra></th>
      </tr>
      <tr *ngFor="let entry of verificationResults">
        <td>{{entry.instit_group_id}}</td>
        <td>{{entry.instit_id}}</td>
        <td>{{entry.test_session_id}}</td>
        <td>{{renderDateFromStr(entry.test_sesstion_created_on)}}</td>        
        <td><button class="button is-link" (click)="downloadForm(entry)"><tra slug="btn_download"></tra></button></td>          
        <td>{{entry.is_verified}}</td>
        <td>{{entry.verified_on}}</td>
        <td>{{entry.verified_by_uid}}</td>
        <td><button *ngIf="!entry.is_verified" class="button is-link" (click)="verifyEntry(entry, true)"><tra slug="btn_verify"></tra></button></td>   
        <td><button *ngIf="entry.is_verified" class="button is-link" (click)="verifyEntry(entry, false)"><tra slug="btn_unverify"></tra></button></td> 
      </tr>
    </table>
  </div>
  <div style="padding:20px;font-weight: bold" *ngIf="!verificationResults || verificationResults.length === 0"><tra slug="msg_no_results"></tra></div>