import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { LangService } from '../../core/lang.service';
import { SEBService } from '../../ui-testtaker/seb.service';
import { TimezoneService } from '../../core/timezone.service';
import { IUserInfo, AuthService } from '../../api/auth.service';
import { Router } from '@angular/router';
import { AccountType } from '../../constants/account-types';
import { Title } from '@angular/platform-browser';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { Subscription } from 'rxjs';

export const getSiteSectionColor = (accountType: AccountType | null) => {
  switch (accountType) {
    case AccountType.TEST_ADMIN : return 'rgba(255, 214, 0, 0.37)';
    case AccountType.TEST_TAKER : return 'rgba(99, 237, 35, 0.37)';
    case AccountType.TEST_CTRL : return 'rgba(236, 64, 64, 0.37)';
    case AccountType.CERT_BODY : return 'rgba(0, 0, 0, 0.37)';
    default: return 'rgba(64, 143, 236, 0.37)';
  }
};

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input() breadcrumbPath: {caption: string, route: string}[];
  @Input() siteSection: boolean;
  @Input() accountInfo: string;
  @Input() hasPrint: boolean;
  @Input() icon: string;
  @Input() accountType: AccountType;
  @Input() newMessage: boolean;
  @Input() messagePoolId: string;

  userInfo: IUserInfo;
  isMenuRevealed: boolean;
  

  langs = [
    {code: 'en', caption: 'English'},
    {code: 'fr', caption: 'Français'},
  ];

  public timeZone: string;
  public usingSEB = false;
  subscription = new Subscription();

  constructor(
    public langService: LangService,
    public timezone: TimezoneService,
    private router: Router,
    private auth: AuthService,
    private seb: SEBService,
    private whitelabel: WhitelabelService,
    private titleService: Title,
    // private headerChatService: ChatService
  ) { }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit() {
    this.subscription.add(
      this.auth
      .user()
      .subscribe(t => this.userInfo = t)
    );
    this.subscription.add(
      this.seb.getSub().subscribe(isSEB => {
        console.log(`header isSEB: ${isSEB}`)
        this.usingSEB = isSEB
      })
    )

    this.updateTitle();
    this.timeZone = this.timezone.getTimezone();
  }

  updateTitle() {
    if (this.breadcrumbPath) {
      const crumb = this.breadcrumbPath[this.breadcrumbPath.length - 1];
      this.titleService.setTitle( this.langService.tra(crumb.caption) + ' | ' + this.langService.tra(this.getBranding()) );
    }
  }

  isUsingSebSoftware(){
    return navigator.userAgent.match(/SEB/i) ? true: false
  }

  isBreadcrumbMenuEnabled() {
    return this.whitelabel.getSiteFlag('BREADCRUMB_MENU');
  }

  getHomeScreenRoute() {
    return this.whitelabel.getHomepageRoute();
  }

  getCrumbRoute(crumb) {
    if (crumb.caption === this.langService.tra('title_create_sessions')) return this.router.url;
    return crumb.route;
  }

  getBranding() {
    return this.whitelabel.currentBrandName;
  }

  getStripColor() {
    return getSiteSectionColor( this.getAccountType() );
  }

  toggleMenu() {
    this.isMenuRevealed = !this.isMenuRevealed;
  }

  getAccountType(useDefault: boolean= false) {
    if (!this.accountType) {
      if (this.userInfo) {
        return this.userInfo.accountType;
      }
    } else {
      return this.accountType;
    }
    if (useDefault) {
      return AccountType.TEST_TAKER;
    }
    return null;
  }

  getUserDisplayName() {
    return this.userInfo.firstName + ' ' + this.userInfo.lastName;
  }

  getLang() {
    return this.langService.getCurrentLanguage();
  }

  isLangActive(langCode: string) {
    return (this.getLang() === langCode);
  }

  setLang(langCode: string) {
    this.langService.loadInLang(langCode);
  }

  getLoginRoute() {
    return `/${this.langService.c()}/${this.getAccountType(true)}/login/true`;
  }

  logout() {
    const loginRoute = this.getLoginRoute();
    this.auth
      .logout()
      .then( r => {
        this.router.navigate([loginRoute]);
        setTimeout(() => {
          window.location.reload();
        }, 300);
      });
  }

  onClickNewMessage() {
    console.log('click');
  }

}
