import { API_ADDRESS_LOCAL } from "../../api/api-addr/l";
import { IContextData } from "./common";

export let MPT_TEST_CTRL_GROUP_ID;


const footer = [
    [
        {"route":"/applicant/learn/about", "caption": "title_aboutmpt"},
        {"route":"/contact", "caption": "title_contactus"},
        {"route":"/applicant/learn/access_accomm", "caption": "title_acc_amm"},
        {"route":"/applicant/learn/register:remote_req", "caption": "title_techreq"}
    ],
    [
        {"route":"/applicant/learn/faq", "caption": "title_faq_app"},
        // {"route":"/testadmin/learn/faq", "caption": "title_faq_adm"},
        {"route":"/applicant/learn/about:for_me", "caption": "title_teach_app"},
        {"link" :"faculties_website", "caption": "title_foe"},
        {"link" :"oct_website", "caption": "title_oct_full"}
    ],
    [
        {"route":"/privacy", "caption": "title_privacy"},
        {"route":"/legal", "caption": "title_legal"}
    ],
    []
  ]

const apiAddress = (hostname:string) => {
    switch(hostname){
      case 'xqc1.mathproficiencytest.ca': return 'https://xqc1-api.mathproficiencytest.ca';
      // case 'qc1.mathproficiencytest.ca': return 'https://api-eassessment.vretta.com';
      // case 'qc1.mathproficiencytest.ca': return 'https://api.mathproficiencytest.ca';
      case 'qc1.mathproficiencytest.ca': return 'https://qc1-api.mathproficiencytest.ca';
      // case 'localhost':
      case 'd2f95x08189c1c.cloudfront.net': return 'https://qc3-api.mathproficiencytest.ca';
      case 'qc3.mathproficiencytest.ca': return 'https://qc3-api.mathproficiencytest.ca';
      case 'qc3-uat.mathproficiencytest.ca': return 'https://api.mathproficiencytest.ca';
      // case 'qc3.mathproficiencytest.ca': return 'https://api.mathproficiencytest.ca';
      // case 'localhost':  return 'https://api-eassessment.vretta.com';
      case 'localhost': 
        // return 'https://qc3-api.mathproficiencytest.ca';
        // return 'https://api.mathproficiencytest.ca';
        return API_ADDRESS_LOCAL;
      // case 'qc3.mathproficiencytest.ca':
      // case 'localhost':
      case 'testcompetencesmaths.ca':
      case 'mathproficiencytest.ca':
      case 'www.testcompetencesmaths.ca':
      case 'www.mathproficiencytest.ca':
      default: return 'https://api.mathproficiencytest.ca';
    }
  }

  const setMPTctrlID = (hostname:string) => {
    console.log(hostname)
    switch(hostname){
      case 'd2f95x08189c1c.cloudfront.net': MPT_TEST_CTRL_GROUP_ID = 27; break;
      case 'qc3.mathproficiencytest.ca':MPT_TEST_CTRL_GROUP_ID = 27; break;
      case 'localhost': MPT_TEST_CTRL_GROUP_ID = 24; break;
      case 'mathproficiencytest.ca': MPT_TEST_CTRL_GROUP_ID = 24; break;
      case 'www.testcompetencesmaths.ca': MPT_TEST_CTRL_GROUP_ID = 24; break;
      case 'www.mathproficiencytest.ca':MPT_TEST_CTRL_GROUP_ID = 24; break;
      default : MPT_TEST_CTRL_GROUP_ID = 24;
    }
  }

export const MPT:IContextData = {
    id: 'MPT',
    homepageRoute: '/mpt-launch',
    brandName: 'brand_short',
    defaultTimezone: 'America/Toronto',
    logo: '',
    footer,
    setMPTctrlID,
    apiAddress,
    siteFlags: {
      BREADCRUMB_MENU: true,
      BOOKING_POLICY_STATS: true,
    },
    siteText: {
      copyright: 'title_copyright',
      supportEmail: 'support@mathproficiencytest.zendesk.com',
      supportPhone: '+1 (877) 960-0127',
      my_inst: 'ta_my_faculty_lbl',
      my_inst_admin: 'lbl_inst_mngr_you',
      txt_support_popup: 'txt_support_popup_mpt',
    },
    hosts: [
        'qc3.mathproficiencytest.ca',
        'localhost:4200',
        'mathproficiencytest.ca',
        'd2f95x08189c1c.cloudfront.net',
        'testcompetencesmaths.ca',
        'www.mathproficiencytest.ca',
        'www.testcompetencesmaths.ca',
        'qc1.mathproficiencytest.ca',
    ]
}
