import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AuthService} from '../api/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SEBService {
  private sub: BehaviorSubject<boolean> = new BehaviorSubject(false);
  
  constructor(
      private auth: AuthService,
  ) {}

  public getSub() {
    return this.sub;  
  }
  
  public check(sessionId: number) {
    this.auth.apiFind('public/test-taker/invigilation/attempt-checklist-seb', {query:{test_session_id: sessionId}})
    .then(res => {
      this.sub.next(true);
    }).catch(e => {
      this.sub.next(false);
    });
  }
}
